


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Profile } from 'client-website-ts-library/types/Profile';

@Component
export default class ProfileCard extends Vue {
  @Prop()
  private readonly profile!: Profile;

  @Prop({ default: true })
  private readonly showContactDetails!: boolean;

  @Prop({ default: true })
  private readonly showOffice!: boolean;

  @Prop({ default: '/people/' })
  private readonly href!: boolean;
}
